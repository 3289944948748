<template>
  <div class="container">
    <TitleComponent class="text-center primary--text text-uppercase" title="COVID UPDATES"/>
    
  <v-row justify="center" class="spacing-playground pt-10">
    <v-col
       cols="6"
    >
     <!-- coviddata-->
      <swiper ref="mySwiper1"  :options="swiperOptionflip">
        <swiper-slide
            v-for="(CovidUpdate, i) in this.CovidUpdates"   
          :key="i"
        >
      
        <v-card
          
            class="pb-2"
            :max-height="$vuetify.breakpoint.mdAndUp ? 350 : 150"
            :max-width="$vuetify.breakpoint.mdAndUp ? 550 : 350"
        >
        
        <v-img
          class="align-center"
          :height="$vuetify.breakpoint.mdAndUp ? 350 : 150"
          :width="$vuetify.breakpoint.mdAndUp ? 550 : 350"
          
          :src="$api_url+CovidUpdate.Url"
          :lazy-src="$api_url+CovidUpdate.Url"
          aspect-ratio=""
          :alt="CovidUpdate.Name"
          :title="CovidUpdate.Name"
        >
       

        </v-img>
        </v-card>
       
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!--<div class="swiper-scrollbar" slot="scrollbar"></div>-->
      </swiper>
     
      <v-footer
        class="font-weight-italic text-center green--text"
      >
      Swipe to see more...
      </v-footer>
     
    </v-col>

    <v-col
      cols="6"
    >
    
     <!-- vaccination data -->
         
    <swiper ref="mySwiper"  :options="swiperOptionflip">
        <swiper-slide
            v-for="(VaccineUpdate, i) in this.VaccineUpdates"   
          :key="i"
        >
       
        <v-card
          
            class="pb-2"
            :max-height="$vuetify.breakpoint.mdAndUp ? 350 : 150"
            :max-width="$vuetify.breakpoint.mdAndUp ? 550 : 350"
        >
       
          <v-img
            class="align-center"
            :height="$vuetify.breakpoint.mdAndUp ? 350 : 150"
            :width="$vuetify.breakpoint.mdAndUp ? 550 : 350"
            
            :src="$api_url+VaccineUpdate.Url"
            :lazy-src="$api_url+VaccineUpdate.Url"
            aspect-ratio=""
            :alt="VaccineUpdate.Name"
            :title="VaccineUpdate.Name"
        >
        
        </v-img>
        
         <!--
        <enlargeable-image :src="$api_url+VaccineUpdate.Url" :src_large="$api_url+VaccineUpdate.Url" /> 
       -->
        </v-card>
       
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!--<div class="swiper-scrollbar" slot="scrollbar"></div>-->
      </swiper>
      <v-footer
        class="font-weight-italic text-center green--text"
      >
      Swipe to see more...
      </v-footer>
    </v-col>

  </v-row>
 
  </div>
</template>

<script>
import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent'
//import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper' 
import 'swiper/css/swiper.css'

export default {
  name : 'CovidUpdates',
  components : {
    TitleComponent,
    Swiper,
    SwiperSlide,
    //EnlargeableImage
  },
   directives: {
    swiper: directive
  },
  data() {
    return {
    CovidUpdates:[],
    VaccineUpdates:[], 
    swiperOptionflip: {
        effect: 'cube',
        grabCursor: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94
        },
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  mounted(){
  
    this.getAllRecords(`/getAllRecords/covidupdates/where ID > 0/ ID desc limit 10`,'covidupdates');
    this.getAllRecords(`/getAllRecords/vaccineupdates/where ID > 0/ ID desc`,'vaccineupdates');
    
  },
  computed: {
   // swiper() {
    //  return this.$refs.mySwiper.$swiper
   // }
  },  
  methods : {
    
    getAllRecords:function(query,tablename){
      //alert(this.BranchCode);       
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(tablename=='covidupdates'){
                this.CovidUpdates=response.data;
              }
              else if(tablename=='vaccineupdates'){
                this.VaccineUpdates = response.data;
              }
              
              
            }
      })
      .catch(error => { console.log(error)})
    },  

  }
}
</script>

<style lang="scss" scoped>
 
</style>